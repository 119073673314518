.navigator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-image: url("../../assets/images/home/navigator.png");
  background-size: 100% 5rem;
}

.navigator .navigator-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  height: 1.944444rem;
  padding: 0 0.555556rem;
}

.navigator .navigator-head>div {
  width: 33%;
  display: flex;
  align-items: center;
}

.navigator .navigator-head>div>img {
  width: 0.555556rem;
  height: 0.555556rem;
  margin: 0 0.194444rem;
}

.left-part {
  position: absolute;
  left: 0.694444rem;
  top: 2.777778rem;
  height: 70vh;
  width: 13.888889rem;
}

.left-part .risk-grade {
  width: 11.111111rem;
  border: 0.055556rem solid #103578;
  height: 40vh;
  padding: 0.694444rem;
  background-color: rgba(0,64,138,.05);
}

.right-part {
  position: absolute;
  right: 0.694444rem;
  top: 2.777778rem;
  height: 70vh;
  width: 11.111111rem;
}

.anywhere-part {
  position: absolute;
}

.options>div {
  height: 0.888889rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options>div:hover {
  background-color: #106fda;
  color: #fff;
}

.panel-title {
  color: #dcfafa;
  font-size: 0.5rem;
  margin-bottom: 0.277778rem;
}

.panel-title>img {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.055556rem;
  position: relative;
  bottom: 0.055556rem;
}

.risk-grade-head {
  display: inline-block;
  width: 7.5rem;
}

.risk-grade-head>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.083333rem;
  border-bottom: 0.027778rem solid #00408a;
}

.risk-grade-head>div .risk-grade-title {
  color: #dcfafa;
  font-size: 0.611111rem;
}

.risk-grade-body .region-card {
  display: flex;
  color: #fff;
  height: 1.444444rem;
  width: 100%;
  background: linear-gradient(0deg, #005bc6, #02070b);
  box-shadow: 0 0 1.805556rem 0 rgba(0,115,250,.31);
  border: 0.027778rem solid #106fda;
  margin-bottom: 0.333333rem;
  align-items: center;
  padding: 0 0.555556rem;
  justify-content: space-between;
  font-size: 0.388889rem;
}

.risk-grade-body .region-card .card-tail {
  display: flex;
  width: 6.666667rem;
  justify-content: space-between;
  align-items: center;
}

.risk-grade-body .region-card .card-tail img {
  width: 0.444444rem;
  height: 0.444444rem;
  margin-right: 0.138889rem;
}

.risk-grade-body .risk-grade-bar {
  margin: 0.333333rem 0;
  width: 100%;
  height: 0.555556rem;
  background: linear-gradient(90deg, #00fa99, #37fdff, #fac800, #ff6c00, #f03200);
  border-radius: 0.277778rem;
}

.year-on-year {
  color: #3dd9d9;
  font-size: 0.333333rem;
}

.year-on-year.red {
  color: #f03200;
}

.risk-grade-body.card-list {
  height: 20vh;
  overflow-y: auto;
}

.risk-grade-body.card-list::-webkit-scrollbar {
  width: 0.277778rem;
}

.risk-grade-body.card-list::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.138889rem;
  border: 0.027778rem solid #106fda;
}