.detail-panel .detail-panel-head {
  display: flex;
  font-size: 0.5rem;
  border-bottom: 0.027778rem solid;
  padding: 0.138889rem 0;
  justify-content: space-between;
  align-items: flex-end;
}

.detail-panel .detail-panel-head .jump-button {
  font-size: 0.388889rem;
  cursor: pointer;
}

.detail-panel .detail-panel-head .jump-button:hover {
  border-bottom: 0.027778rem solid;
}

.detail-panel .detail-panel-body {
  padding: 0.277778rem 0.555556rem;
}

.detail-panel .detail-panel-body li {
  list-style-type: disc;
  padding: 0.083333rem;
}

.year-on-year {
  color: #3dd9d9;
  font-size: 0.333333rem;
}

.panel-title {
  color: #dcfafa;
  font-size: 0.5rem;
  margin-bottom: 0.277778rem;
}

.risk-grade-body.no-scroll::-webkit-scrollbar {
  width: 0.277778rem;
}

.risk-grade-body.no-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0.138889rem;
  border: 0.027778rem solid #106fda;
}

.risk-grade-body .region-card {
  display: flex;
  color: #fff;
  height: 1.444444rem;
  width: 100%;
  background: linear-gradient(0deg, #005bc6, #02070b);
  box-shadow: 0 0 1.805556rem 0 rgba(0,115,250,.31);
  border: 0.027778rem solid #106fda;
  margin-bottom: 0.333333rem;
  align-items: center;
  padding: 0 0.555556rem;
  justify-content: space-between;
  font-size: 0.388889rem;
}

.risk-grade-body .region-card .card-tail {
  display: flex;
  width: 3.333333rem;
  justify-content: space-between;
  align-items: center;
}

.risk-grade-body .region-card .card-tail img {
  width: 0.444444rem;
  height: 0.444444rem;
  margin-right: 0.138889rem;
}

.risk-grade-body .risk-grade-bar {
  margin: 0.333333rem 0;
  width: 100%;
  height: 0.555556rem;
  background: linear-gradient(90deg, #00fa99, #37fdff, #fac800, #ff6c00, #f03200);
  border-radius: 0.277778rem;
}

.bottom-button {
  width: calc(50% - .8rem);
  background: linear-gradient(0deg, #005bc6, #02070b);
  box-shadow: 0 0 1.805556rem 0 rgba(0,115,250,.31);
  color: #fff;
}